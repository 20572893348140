'use strict';

import * as Sentry from '@sentry/browser';
import { extraErrorDataIntegration, captureConsoleIntegration, contextLinesIntegration, httpClientIntegration } from '@sentry/integrations';

export default function ({
  dsn,
  env,
  release,
  userId,
  tracesSampleRate = 0.4
}) {
  if (dsn) {
    Sentry.init({
      dsn,
      environment: env,
      release,
      integrations: [
        Sentry.browserTracingIntegration({
          tracePropagationTargets: ['*']
        }),
        Sentry.browserApiErrorsIntegration(),
        extraErrorDataIntegration(),
        captureConsoleIntegration(),
        contextLinesIntegration(),
        httpClientIntegration()
      ],
      tracesSampleRate
    });
    if (userId)
      Sentry.setUser({ id: userId });
  }

  return Sentry;
}
